<template>
  <v-row no-gutters>
    <v-col cols="11">
      <defaultFieldTypes
        :fieldAttributes="fieldAttributes"
        :field="field"
        :templateContent="result"
        v-on="$listeners"
        @revalidate="$emit('revalidate')"
        :value="keyValue"
      ></defaultFieldTypes>
    </v-col>
    <!-- <a  > -->
      <v-col>
          <v-icon class="mt-2 ml-2"
                  @click="randomHashGenerator"
                  :disabled="disabled"
          >mdi-auto-fix</v-icon>
      </v-col>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  data: function () {
    return {
      keyValue:typeof this.value!="undefined"?this.value: this.random128Hex()
    };
  },
  props: ["result", "field", "fieldAttributes","value"],
  components: { defaultFieldTypes },
  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },

  methods: {
  randomHashGenerator() {
      /*this.$emit("input", this.random128Hex());
      this.$nextTick(function () {
        this.$emit("revalidate");
      });*/
      this.keyValue=this.random128Hex();
    },

    /** random 128-bit number in canonical uuid format. all bits are random. */
    random128Hex() {
      function random8Hex() {
        return (0x100 | (Math.random() * 0x100)).toString(16).substr(1);
      }
      let randomArray = [];
      for (let i = 0; i < 16; i++) {
        randomArray.push(random8Hex());
      }
      return randomArray.join(" ");
    },
  },
};
</script>